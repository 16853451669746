export const TEST_ID = {
  appFooter: 'appFooter',
  appFooterLogo: 'appFooterLogo',
  appFooterContacts: 'appFooterContacts',
  appFooterHelpLinks: 'appFooterHelpLinks',
  appFooterFollowUs: 'appFooterFollowUs',
  appFooterFollowUsLinks: 'appFooterFollowUsLinks',
  appFooterContactsAddress: 'appFooterContactsAddress',
  sectionTitle: 'sectionTitle',
  sectionDescription: 'sectionDescription',
  emptyState: 'emptyState',
  topNavigation: 'topNavigation',
  logout: 'logout',
  accountSettings: 'accountSettings',
  externalLink: 'externalLink',
  icon: 'icon',
  appContent: 'appContent',
  actionIcon: 'actionIcon',
  appHeaderLogo: 'appHeaderLogo',
  showMoreButton: 'showMoreButton',
  passwordField: 'passwordField',
  hideButton: 'hideButton',
  loader: 'loader',
  cancelButton: 'cancelButton',
  nextButton: 'nextButton',
  activateButton: 'activateButton',
  submitButton: 'submitButton',
  sectionContent: 'sectionContent',
  tagContainer: 'tagContainer',
  closeIcon: 'closeIcon',
  loaderContainer: 'loaderContainer',
  breadcrumbs: 'breadcrumbs',
  searchInput: 'searchInput',
  fileUploader: 'fileUploader',
  breadcrumbsBack: 'breadcrumbsBack',
  copyButton: 'copyButton',
  keyInfoBlock: 'keyInfoBlock',
  apiKeyModalTitle: 'apiKeyModalTitle',
  apiKeyField: 'apiKeyField',
  copyModalButton: 'copyModalButton',
  closeModalButton: 'closeModalButton',
};

export const DUPLICATED_NAME = 'duplicated_name';
export const CRM_AUDIENCES_LIMIT_REACHED = 'crm_audiences_limit_reached';
export const LOOKALIKE_AUDIENCES_LIMIT_REACHED = 'lookalike_audiences_limit_reached';
