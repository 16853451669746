export interface ThemeColors {
  purplePrimary: string;
  purpleHover: string;
  purple80: string;
  purple60: string;
  purple40: string;
  purple20: string;
  pinkPrimary: string;
  pinkHover: string;
  pink40: string;
  pink20: string;
  pink10: string;
  red: string;
  yellowPrimary: string;
  yellow40: string;
  yellow20: string;
  gray30: string;
  gray10: string;
  grayPrimary: string;
  gray120: string;
  blackPrimary: string;
  gray40: string;
  green20: string;
  white: string;
  lightBlack: string;
  lightGray: string;
  gray20: string;
  lightYellow: string;
  lightGreen: string;
  red20: string;
  redPrimary: string;
  redDark: string;
  dropShadow: string;
}

export const themeColors: ThemeColors = {
  purplePrimary: '#783C96',
  purpleHover: '#412052',
  purple80: '#9363AB',
  purple60: '#AE8AC0',
  purple40: '#D3BFDD',
  purple20: '#F1EAF4',
  pinkPrimary: '#D23278',
  pinkHover: '#942757',
  pink40: '#EDADC9',
  pink20: '#F6D6E4',
  pink10: '#FAEAF1',
  red: '#D64036',
  yellowPrimary: '#FABB22',
  yellow40: '#FDE4A7',
  yellow20: '#FFF5DE',
  gray30: '#E4E4E4',
  gray20: '#EFEFEF',
  gray10: '#F6F6F6',
  grayPrimary: '#A5A5A5',
  gray120: '#757575',
  blackPrimary: '#000000',
  gray40: 'rgba(165, 165, 165, 0.4)',
  green20: '#E3F4CC',
  white: '#FFFFFF',
  lightBlack: '#232323',
  lightGray: '#C9C9C9',
  lightYellow: '#FFF5DE',
  lightGreen: '#73C800',
  red20: '#FCE4E2',
  redPrimary: '#E6463C',
  redDark: '#AB342D',
  dropShadow: 'rgba(0,0,0, 0.1)',
};
