import Keycloak from 'keycloak-js';

export const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOCK_URL,
  realm: process.env.REACT_APP_KEYCLOCK_REALM,
  clientId: process.env.REACT_APP_KEYCLOCK_CLIENT_ID,
  scope: process.env.REACT_APP_KEYCLOCK_SCOPE,
};

export const keycloakInstance = new Keycloak(keycloakConfig);
