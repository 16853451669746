import React from 'react';

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import { TEST_ID } from 'components/constants';
import { getStyles } from 'components/loader/styles';

type Props = {
  width: number;
  showProgress?: boolean;
  progress?: number;
};

const Loader: React.FC<Props> = ({ width, showProgress, progress }) => {
  const styles = getStyles(width);
  return (
    <Box display="grid" flexDirection="column" justifyItems="center">
      <div data-testid={TEST_ID.loaderContainer}>
        <Box sx={{ width, height: width / 1.2, pt: `${width / 10}px` }}>
          <Box sx={styles.box}>
            <Box sx={styles.purple} />
            <Box sx={styles.rose} />
            <Box sx={styles.orangered} />
            <Box sx={styles.gold} />
          </Box>
        </Box>
      </div>
      {showProgress && (
        <Typography
          variant="h1"
          component="div"
          color="primary"
        >{`${progress}%`}</Typography>
      )}
    </Box>
  );
};

export { Loader };
