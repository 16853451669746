import React, { lazy, Suspense } from 'react';
import TagManager from 'react-gtm-module';

import { Box } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import { ReactKeycloakProvider } from '@react-keycloak/web';

import { Loader } from 'components/loader/Loader';
import { keycloakInstance } from 'utils/keycloak/keycloakConfig';

const tagManagerArgs = {
  gtmId: String(process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY),
};

TagManager.initialize(tagManagerArgs);

LicenseInfo.setLicenseKey(process.env.REACT_APP_DATAGRID_TOKEN || '');

const Providers = lazy(() => import('components/providers/Providers'));

const App: React.FC = () => (
  <ReactKeycloakProvider
    authClient={keycloakInstance}
    LoadingComponent={
      <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
        <Loader width={300} />
      </Box>
    }
  >
    <Suspense
      fallback={
        <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
          <Loader width={300} />
        </Box>
      }
    >
      <Providers />
    </Suspense>
  </ReactKeycloakProvider>
);

export default App;
